<div class="auth-container">
  <img src="/assets/zeva-logo-no-icon.png" class="floating-logo" routerLink="/" />

  <div class="document-page-content-wrapper content-wrapper">
    <p class="mat-headline-2">{{ documentName | transloco }}</p>

    <zeva-card class="tile auto-height content">
      <div *ngIf="documentHTML" [innerHTML]="documentHTML"></div>
      <!-- <iframe [src]="documentURL" *ngIf="documentURL"></iframe> -->
    </zeva-card>
  </div>

  <core-footer></core-footer>
</div>
