<ng-container *ngFor="let announcement of announcements">
  <div class="announcement" *ngIf="announcement.visible">
    <a *ngIf="announcement.link" class="link-announcement" [routerLink]="announcement.link">
      {{ announcement.message }}
    </a>
    <span *ngIf="!announcement.link">{{ announcement.message }}</span>
    <i class="material-icons" (click)="$event.preventDefault(); announcement.visible = false"
      >close</i
    >
  </div>
</ng-container>

<mat-toolbar role="heading" class="zeva-header">
  <div class="left-header">
    <!-- <div class="sidenav-trigger" (click)="toggleSidenav()">
      <mat-icon>{{ sidenavService.opened ? 'close' : 'menu' }}</mat-icon>
    </div> -->
    <div class="sidenav-logo-container">
      <img
        src="/assets/zeva-logo-{{
          (themeService.currentTheme$ | async) === Theme.LIGHT ? 'dark-blue' : 'white'
        }}.svg"
        class="sidenav-logo"
      />
    </div>

    <div class="telemetry-notice" *ngIf="showTelemetryPrereqs()">
      <div style="display: flex; align-items: center">
        <mat-icon> warning_amber </mat-icon>
        <div class="telemetry-info">
          <p>{{ 'header.telemetryNotice' | transloco }}</p>
          <a (click)="telemetryPopup()">{{ 'header.view' | transloco }}</a>
        </div>
      </div>
    </div>
  </div>

  <div class="right-header">
    <ng-container *ngIf="quickAccessTemplate !== undefined">
      <div class="quick-access">
        <ng-container *ngTemplateOutlet="quickAccessTemplate"></ng-container>
      </div>

      <mat-divider [vertical]="true" />
    </ng-container>

    <!-- TODO: These may return when their functionality is implemented -->
    <!-- <mat-icon class="disabled header-search">search</mat-icon> -->
    <!-- <mat-icon class="disabled header-notifications">notifications</mat-icon> -->

    <!-- <button
      mat-flat-button
      color="primary"
      [routerLink]="'/admin/manage-subscription'"
      *ngIf="subscriptionService.isFreePlan$ | async"
      style="margin-right: 1rem"
    >
      Upgrade to Premium
    </button> -->

    <!-- Account Management Menu -->
    <div
      *ngIf="user$ | async as user"
      class="header-account"
      cdkOverlayOrigin
      [popoverTriggerFor]="menuOverlay"
    >
      <div class="full">
        <span class="name">
          {{ user.first_name }}
          <ng-container *ngIf="user.last_name"> {{ user.last_name }}</ng-container>
        </span>
        <!-- Show account name if FM & SI account is selected -->
        <div
          class="account th-color-secondary-text"
          *ngIf="account && account.data_type !== AccountType.IN"
        >
          <span class="subscript">{{ 'header.account' | transloco }}</span>
          {{ account.company_name }}
        </div>
        <!-- Show user Email if IN account or no account is selected -->
        <div
          class="account th-color-secondary-text"
          *ngIf="!account || account.data_type === AccountType.IN"
        >
          <span class="subscript">{{ user.email }}</span>
        </div>
      </div>
      <span class="short">
        <mat-icon>person_outline</mat-icon>
      </span>
    </div>
    <shared-popover #menuOverlay backdropClass="cdk-overlay-dark-backdrop">
      <div class="zeva-card elevated account-menu">
        <ng-container *ngIf="accounts.length > 1">
          <button mat-flat-button (click)="onChangeAccount()">
            <mat-icon>transit_enterexit</mat-icon>
            <span>{{
              account ? ('header.changeAccount' | transloco) : ('header.selectAccount' | transloco)
            }}</span>
          </button>
          <!-- <mat-divider></mat-divider> -->
        </ng-container>
        <button mat-flat-button routerLink="/admin/profile">
          <mat-icon>tune</mat-icon>
          <span>{{ 'header.preferences' | transloco }}</span>
        </button>
        <!-- <mat-divider></mat-divider> -->
        <button mat-flat-button (click)="supportClick()">
          <mat-icon>contact_support</mat-icon>
          <span>{{ 'header.help' | transloco }}</span>
        </button>
        <!-- <mat-divider></mat-divider> -->
        <button mat-flat-button (click)="feedbackClick()">
          <mat-icon>feedback</mat-icon>
          <span>{{ 'header.feedback' | transloco }}</span>
        </button>
        <!-- <mat-divider></mat-divider> -->
        <button mat-flat-button (click)="onSignOut()">
          <mat-icon>logout</mat-icon>
          <span>{{ 'header.signOut' | transloco }}</span>
        </button>
      </div>
    </shared-popover>
  </div>
</mat-toolbar>
