import { Pipe, PipeTransform } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { LegendItem } from '@temerity-analytics/ngx-ta-echarts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Pipe for common js Array.prototype.includes() method
 * @usage in html template: `[10, 20, 30] | includes: 10`
 */
@Pipe({
  name: 'includes',
  pure: true,
  standalone: true,
})
export class ArrayIncludesPipe<T> implements PipeTransform {
  transform(array: Array<T>, value: T, extractorFn: (o: T) => unknown = val => val): boolean {
    return array?.findIndex(v => extractorFn(v) === value) !== -1;
  }
}
/**
 * Asynchronous pipe providing Array.prototype.includes() for checking array values
 * that are updated asynchronously and cannot trigger change detection themselves
 * @usage in html template `(of([10, 20, 30]) | includes: 10) | async`
 */
@Pipe({
  name: 'includes$',
  pure: true,
  standalone: true,
})
export class ArrayIncludesPipeAsync<T> implements PipeTransform {
  transform(
    array$: Observable<Array<T>>,
    value: T,
    extractorFn: (o: T) => unknown = val => val
  ): Observable<boolean> {
    return array$.pipe(map(emission => emission?.findIndex(v => extractorFn(v) === value) !== -1));
  }
}

/**
 * Pipe for common js Set.prototype.has() method
 * @usage in html template: `new Set(['plus', 'ultra']) | has: 'ultra'`
 */
@Pipe({
  name: 'has',
  pure: true,
  standalone: true,
})
export class SetHasPipe<T> implements PipeTransform {
  transform(set: Set<T>, value: T): boolean {
    return set.has(value);
  }
}
/**
 * Asynchronous pipe providing Set.prototype.has() for checking Set values
 * that are updated asynchronously and cannot trigger change detection themselves
 * @usage in html template: `(new Set(['plus', 'ultra']) | has: 'ultra') | async`
 */
@Pipe({
  name: 'has$',
  pure: true,
  standalone: true,
})
export class SetHasPipeAsync<T> implements PipeTransform {
  transform(set$: Observable<Set<T>>, value: T): Observable<boolean> {
    return set$.pipe(map(emission => emission.has(value)));
  }
}

@Pipe({
  name: 'absolute',
  pure: true,
  standalone: true,
})
export class AbsoluteValuePipe implements PipeTransform {
  transform(value: number | undefined | null) {
    return typeof value === 'number' ? Math.abs(value) : value;
  }
}

@Pipe({
  name: 'itrToString',
  pure: true,
  standalone: true,
})
export class IterableToStringPipe implements PipeTransform {
  transform(value: Array<unknown> | Set<unknown>, maxItemsLength?: number): string {
    let valueArray = Array.from(value);
    const valueSize = valueArray.length;

    if (maxItemsLength !== undefined) valueArray = valueArray.slice(-maxItemsLength);

    let valueString = valueArray.join(', ');

    const itemsLeft = valueSize - (maxItemsLength || valueSize);
    if (itemsLeft > 0)
      valueString += ' and ' + itemsLeft.toString() + ' other' + (itemsLeft > 1 ? 's' : '');

    return valueString;
  }
}

@Pipe({
  name: 'slice',
  pure: true,
  standalone: true,
})
export class IterableSlicePipe implements PipeTransform {
  transform<T>(value: Array<T> | Set<T>, start?: number, end?: number): Array<T> {
    return Array.from(value).slice(start, end);
  }
}

@Pipe({
  name: 'slice$',
  pure: true,
  standalone: true,
})
export class IterableSliceAsyncPipe implements PipeTransform {
  transform<T>(
    value: Observable<Array<T> | Set<T>>,
    start?: number,
    end?: number
  ): Observable<Array<T>> {
    return value.pipe(map(v => Array.from(v).slice(start, end)));
  }
}

@Pipe({
  name: 'itrToMatTableDataSource',
  pure: true,
  standalone: true,
})
export class IterableToMatTableDataSourcePipe implements PipeTransform {
  transform(data: readonly LegendItem[]): MatTableDataSource<LegendItem> {
    const legendItems = Array.from(data);
    const sortedItems = [...legendItems].sort((a, b) => {
      return Number(b.data) - Number(a.data);
    });
    const resultItems = sortedItems.map((item, index) => {
      return {
        ...item,
        hexColor: legendItems[index].hexColor,
      };
    });
    const dataSource = new MatTableDataSource(resultItems);
    return dataSource;
  }
}
