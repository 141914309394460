import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Data } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription, of } from 'rxjs';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { APP_VERSION } from '../../app.component';

@Component({
  selector: 'app-document-page',
  templateUrl: './document-page.component.html',
  styleUrls: ['./document-page.component.scss'],
})
export class DocumentPageComponent implements OnInit, OnDestroy {
  private _subscription = new Subscription();

  public readonly year = new Date().getFullYear();
  public readonly appVersion = APP_VERSION;

  public documentName: string | undefined;
  public documentHTML: string | undefined;
  public documentURL: SafeUrl | undefined;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private translocoService: TranslocoService
  ) {}

  ngOnInit() {
    let preferredLanguage = window.localStorage.getItem('preferredLanguage');
    preferredLanguage = preferredLanguage ? preferredLanguage.toString() : 'en';

    const translations$ = this.translocoService.load(preferredLanguage);

    const params$ = this.route.data.pipe(
      mergeMap((data: Data) => {
        this.documentName = data['title'];
        if (this.documentName !== undefined) {
          const url = `/assets/documents/${this.documentName}.html`;
          this.documentURL = this.sanitizer.bypassSecurityTrustUrl(url);

          return translations$.pipe(
            switchMap(() =>
              this.http.get(url, { responseType: 'text' }).pipe(
                tap((content: string) => {
                  this.documentHTML = this.translateHTML(content, preferredLanguage);
                })
              )
            )
          );
        }
        return of();
      })
    );

    this._subscription.add(params$.subscribe());
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private translateHTML(content: string, preferredLanguage: string): string {
    let translatedContent = content;

    const regex = /{{\s*'(.*?)'\s*\|.*?transloco\s*}}/g;
    translatedContent = translatedContent.replace(regex, (match, key) => {
      // replace 'en' with preferredLanguage once we decide to translate legal documents
      const translation = this.translocoService.translate(key, {}, 'en');
      return translation || match;
    });

    return translatedContent;
  }
}
