<div class="sidenav-container">
  <mat-accordion class="menu-holder" displayMode="flat">
    <ng-container *ngIf="hasVehicles || (subscriptionService.isFreePlan$ | async); else getStarted">
      <ng-container *ngFor="let item of navigationPages">
        <ng-container
          *ngTemplateOutlet="pageButtonTemplate; context: { page: item, level: 0 }"
        ></ng-container>
      </ng-container>
    </ng-container>
  </mat-accordion>

  <div class="extras" *ngIf="sidenav.extraMenu">
    <mat-divider></mat-divider>
    <ng-container *ngTemplateOutlet="sidenav.extraMenu"></ng-container>
  </div>

  <!-- <div class="smaller-links">
    <mat-accordion class="menu-holder" displayMode="flat">
      <ng-container *ngFor="let item of externalLinks">
        <ng-container
          *ngTemplateOutlet="pageButtonTemplate; context: { page: item, level: 0 }"
        ></ng-container>
      </ng-container>
    </mat-accordion>
  </div> -->
  <div class="smaller-links mat-caption">
    <ng-container *ngFor="let item of externalLinks">
      <!-- External Website Link -->
      <a *ngIf="item.externalLink" [href]="item.link">
        <ng-container *ngTemplateOutlet="linkDisplay; context: { item }" />
      </a>
      <!-- Internal Link -->
      <a
        *ngIf="!item.externalLink"
        [routerLink]="item.link"
        (click)="item.action ? item.action(dialog) : null"
      >
        <ng-container *ngTemplateOutlet="linkDisplay; context: { item }" />
      </a>
    </ng-container>
    <ng-template #linkDisplay let-item="item">
      <mat-icon *ngIf="item.icon" [svgIcon]="item.svgIcon ? item.icon : ''">
        {{ item.icon }}
      </mat-icon>
      <span>{{
        item.name === 'zevaglobal.com' ? item.name : ('pages.' + item.name | transloco)
      }}</span>
    </ng-template>
  </div>
</div>

<ng-template #getStarted>
  <ng-container *ngFor="let item of getStartedPages">
    <ng-container
      *ngTemplateOutlet="pageButtonTemplate; context: { page: item, level: 0 }"
    ></ng-container>
  </ng-container>
</ng-template>

<ng-template #pageButtonTemplate let-page="page" let-level="level">
  <mat-expansion-panel
    *ngIf="
      page.visible !== false &&
      (!page.onlyForInstance || (page.onlyForInstance | includes : (subscriptionService.company$ | async)?.data_type)) &&
      ((page.name !== 'Account' && page.name !== 'Fleet' && page.name !== 'Subscription') ||
        (page.permissionsRequired | hasPermissions | async))
    "
    class="menu-item"
    [ngClass]="{
      active: page.subPages === undefined && (page | isActive | async),
      disabled: page.disabled,
      disabled_perms: !(
        page.name !== 'Profile' && page.permissionsRequired
        | hasPermissions
        | async
      )
    }"
    [expanded]="page.subPages && page | isActive | async"
    [disabled]="page.disabled || page.subPages === undefined"
    [hideToggle]="
      page.subPages === undefined || !(page.permissionsRequired | hasPermissions | async)
    "
  >
    <mat-expansion-panel-header
      class="link"
      (click)="
        page.name !== 'Get Started'
          ? page.subPages === undefined && handleLinkClick(page)
          : handleGetStartedClick()
      "
      *ngIf="showParentPage(page) | async"
      expandedHeight="48px"
    >
      <mat-panel-title class="title">
        <div
          class="main-title-components"
          [ngClass]="{ getStarted: page.name === 'Get Started' && getStartedClicked }"
        >
          <mat-icon
            *ngIf="page.icon !== undefined"
            class="material-icons-rounded"
            [svgIcon]="page.svgIcon ? page.icon : undefined"
          >
            {{ page.svgIcon ? '' : page.icon }}
          </mat-icon>
          <span class="menu-link-title">{{ 'pages.' + page.name | transloco }}</span>
        </div>

        <div
          class="premium-container"
          *ngIf="
            !(subscriptionService.isCommercialPlan$ | async) &&
            (page.name === 'Energy' || page.name === '')
          "
        >
          <mat-icon class="premium"> star </mat-icon>
        </div>

        <div
          class="locked-container"
          *ngIf="!(page.name !== 'Profile' && page.permissionsRequired | hasPermissions | async)"
        >
          <mat-icon class="locked"> lock_outline </mat-icon>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-expansion-panel-header
      class="disabled_perms link"
      *ngIf="!(showParentPage(page) | async)"
      expandedHeight="48px"
    >
      <mat-panel-title class="title">
        <div class="main-title-components">
          <mat-icon
            *ngIf="page.icon !== undefined"
            class="material-icons-rounded"
            [svgIcon]="page.svgIcon ? page.icon : undefined"
          >
            {{ page.svgIcon ? '' : page.icon }}
          </mat-icon>
          <span class="menu-link-title">{{
            page.name === 'zevaglobal.com' ? page.name : ('pages.' + page.name | transloco)
          }}</span>
        </div>
        <div class="locked-container">
          <mat-icon class="locked"> lock_outline </mat-icon>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-accordion
      displayMode="flat"
      *ngIf="page.subPages !== undefined && (showParentPage(page) | async)"
      class="sub-menu-container"
    >
      <ng-container *ngFor="let subPage of page.subPages">
        <ng-container
          *ngTemplateOutlet="pageButtonTemplate; context: { page: subPage, level: level + 1 }"
        ></ng-container>
      </ng-container>
    </mat-accordion>
  </mat-expansion-panel>
</ng-template>
