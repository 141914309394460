/**
 * Vehicle states
 */
export enum VehicleStates {
  ONLINE = 'online', // A vehicle that is online
  PARKED = 'parked', // A vehicle that is parked
  MOVING = 'moving', // A vehicle that is currently moving
  // eslint-disable-next-line max-len
  STOPPED = 'stopped', // A vehicle that is not currently moving but whose shift is in a moving position (eg. sitting in traffic)
  CHARGING = 'charging', // A vehicle that is charging
  ALERT = 'alert', // A vehicle that is currently triggering an alert
  ASLEEP = 'asleep', // A vehicle that is asleep (no real-time data)
}

/**
 * Vehicle alerts
 */
export enum VehicleAlerts {
  BATTERY = 'low_battery',
  OVERCHARGE = 'overcharge',
  SPEED = 'speed',
  GEOFENCE = 'geofence',
}

/**
 * Vehicle state icons
 */
export const VEHICLE_STATE_ICONS: {
  [state: string]: { icon: string; color: string; darkColor: string };
} = {
  [VehicleStates.ONLINE]: { icon: 'online', color: '#6CA241', darkColor: '#0052FF' },
  [VehicleStates.PARKED]: { icon: 'parking', color: '#6a0dad', darkColor: '#FF653E' },
  [VehicleStates.MOVING]: { icon: 'moving', color: '#0064B4', darkColor: '#1D82DC' },
  [VehicleStates.STOPPED]: { icon: 'pause', color: '#FA9C1B', darkColor: '#FA9C1B' },
  [VehicleStates.CHARGING]: { icon: 'charging', color: '#FFC843', darkColor: '#FFE620' },
  [VehicleStates.ALERT]: { icon: 'alerts', color: '#FF001B', darkColor: '#FF001B' },
  [VehicleStates.ASLEEP]: { icon: 'moon', color: '#808080', darkColor: '#808080' },
  speed: { icon: 'speed', color: '#000000', darkColor: '#000000' },
  custom: { icon: 'search', color: '#a5a5a5', darkColor: '#a5a5a5' },
};

/**
 * Vehicle alert icons
 */
export const VEHICLE_ALERT_ICONS: {
  [key in VehicleAlerts | string]: { icon: string; color: string };
} = {
  [VehicleAlerts.BATTERY]: { icon: 'battery_1_bar', color: '#4a4a4a' },
  [VehicleAlerts.OVERCHARGE]: { icon: 'battery_alert', color: '#4a4a4a' },
  [VehicleAlerts.GEOFENCE]: { icon: 'fmd_bad', color: '#4a4a4a' },
  [VehicleAlerts.SPEED]: { icon: 'speed', color: '#4a4a4a' },
};

/**
 * Tooltip messages
 */
export const DBS_BRAKING_TOOLTIP = 'live.dbsBrakingTooltip';
export const DBS_SPEEDING_TOOLTIP = 'live.dbsSpeedingTooltip';
export const DBS_ACCELERATION_TOOLTIP = 'live.dbsAccelerationTooltip';
export const RANGE_ESTIMATE_TOOLTIP = 'live.rangeEstimateTooltip';
export const TIRE_PRESSURE_TOOLTIP = 'live.tirePressureTooltip';
