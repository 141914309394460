import { MatDialog } from '@angular/material/dialog';
import { CompanyDataType } from '../../auth/utility/auth.models';
import { ZevaPageSelector } from '../core.models';
import { ZEVA_PAGES, ZEVA_PAGE_VIEW_PERMISSION, ZEVA_SUBSYSTEMS } from '../core.static';
import { FeedbackModalComponent } from '../feedback/feedback-modal.component';
import { MODAL_HEIGHT, MODAL_ID, MODAL_WIDTH } from '../support/support.static';
import { NavigationElement } from './sidenav.models';

/* Array that determines the navigation menu */
export const DEFAULT_NAVIGATION: NavigationElement[] = [
  {
    name: ZEVA_SUBSYSTEMS.monitoring.name,
    link: '/' + ZEVA_SUBSYSTEMS.monitoring.slug,
    icon: 'directions_car',
    subPages: [
      {
        name: ZEVA_PAGES[ZevaPageSelector.MONITORING_LIVE].name,
        // eslint-disable-next-line max-len
        link: `/${ZEVA_SUBSYSTEMS.monitoring.slug}/${
          ZEVA_PAGES[ZevaPageSelector.MONITORING_LIVE].slug
        }`,
        icon: 'wifi',
        permissionsRequired: [ZEVA_PAGE_VIEW_PERMISSION[ZevaPageSelector.MONITORING_LIVE]],
      },
      {
        name: ZEVA_PAGES[ZevaPageSelector.MONITORING_REPLAY].name,
        // eslint-disable-next-line max-len
        link: `/${ZEVA_SUBSYSTEMS.monitoring.slug}/${
          ZEVA_PAGES[ZevaPageSelector.MONITORING_REPLAY].slug
        }`,
        icon: 'route',
        permissionsRequired: [ZEVA_PAGE_VIEW_PERMISSION[ZevaPageSelector.MONITORING_REPLAY]],
      },
      {
        name: ZEVA_PAGES[ZevaPageSelector.MONITORING_EN].name,
        // eslint-disable-next-line max-len
        link: `/${ZEVA_SUBSYSTEMS.monitoring.slug}/${
          ZEVA_PAGES[ZevaPageSelector.MONITORING_EN].slug
        }`,
        icon: 'battery_charging_full',
        permissionsRequired: [ZEVA_PAGE_VIEW_PERMISSION[ZevaPageSelector.MONITORING_EN]],
      },
      {
        name: ZEVA_PAGES[ZevaPageSelector.MONITORING_CONTROL].name,
        // eslint-disable-next-line max-len
        link: `/${ZEVA_SUBSYSTEMS.monitoring.slug}/${
          ZEVA_PAGES[ZevaPageSelector.MONITORING_CONTROL].slug
        }`,
        icon: 'settings_remote',
        permissionsRequired: [ZEVA_PAGE_VIEW_PERMISSION[ZevaPageSelector.MONITORING_CONTROL]],
      },
      {
        name: ZEVA_PAGES[ZevaPageSelector.MONITORING_GEOFENCES].name,
        // eslint-disable-next-line max-len
        link: `/${ZEVA_SUBSYSTEMS.monitoring.slug}/${
          ZEVA_PAGES[ZevaPageSelector.MONITORING_GEOFENCES].slug
        }`,
        icon: 'fence',
        permissionsRequired: [ZEVA_PAGE_VIEW_PERMISSION[ZevaPageSelector.MONITORING_GEOFENCES]],
      },
    ],
  },
  {
    name: ZEVA_SUBSYSTEMS.insights.name,
    link: '/' + ZEVA_SUBSYSTEMS.insights.slug,
    icon: 'speed',
    permissionsRequired: [ZEVA_PAGE_VIEW_PERMISSION[ZevaPageSelector.INSIGHTS]],
  },
  {
    name: ZEVA_SUBSYSTEMS.analytics.name,
    link: '/' + ZEVA_SUBSYSTEMS.analytics.slug,
    icon: 'query_stats',
    permissionsRequired: [ZEVA_PAGE_VIEW_PERMISSION[ZevaPageSelector.ANALYTICS_VEHICLE]],
  },
  {
    name: ZEVA_SUBSYSTEMS.maintenance.name,
    link: '/' + ZEVA_SUBSYSTEMS.maintenance.slug,
    icon: 'settings',
    permissionsRequired: [ZEVA_PAGE_VIEW_PERMISSION[ZevaPageSelector.MAINTENANCE_LIST]],
    onlyForInstance: [CompanyDataType.FM],
  },
  {
    name: ZEVA_SUBSYSTEMS.admin.name,
    link: '/' + ZEVA_SUBSYSTEMS.admin.slug,
    icon: 'tune',
    subPages: [
      {
        name: ZEVA_PAGES[ZevaPageSelector.ADMIN_ACCOUNT].name,
        link: `/${ZEVA_SUBSYSTEMS.admin.slug}/${ZEVA_PAGES[ZevaPageSelector.ADMIN_ACCOUNT].slug}`,
        icon: 'business_center',
        permissionsRequired: [ZEVA_PAGE_VIEW_PERMISSION[ZevaPageSelector.ADMIN_ACCOUNT]],
      },
      {
        name: ZEVA_PAGES[ZevaPageSelector.ADMIN_PROFILE].name,
        link: `/${ZEVA_SUBSYSTEMS.admin.slug}/${ZEVA_PAGES[ZevaPageSelector.ADMIN_PROFILE].slug}`,
        icon: 'manage_accounts',
        permissionsRequired: [ZEVA_PAGE_VIEW_PERMISSION[ZevaPageSelector.ADMIN_PROFILE]],
      },
      {
        name: ZEVA_PAGES[ZevaPageSelector.ADMIN_FLEET].name,
        link: `/${ZEVA_SUBSYSTEMS.admin.slug}/${ZEVA_PAGES[ZevaPageSelector.ADMIN_FLEET].slug}`,
        icon: 'garage',
        permissionsRequired: [ZEVA_PAGE_VIEW_PERMISSION[ZevaPageSelector.ADMIN_FLEET]],
      },
      {
        name: ZEVA_PAGES[ZevaPageSelector.ADMIN_SUBSCRIPTION].name,
        link: `/${ZEVA_SUBSYSTEMS.admin.slug}/${
          ZEVA_PAGES[ZevaPageSelector.ADMIN_SUBSCRIPTION].slug
        }`,
        icon: 'star',
        permissionsRequired: [ZEVA_PAGE_VIEW_PERMISSION[ZevaPageSelector.ADMIN_SUBSCRIPTION]],
      },
    ],
  },
];

/* Array that determines the get started menu */
export const GET_STARTED_NAVIGATION: NavigationElement[] = [
  {
    name: ZEVA_PAGES[ZevaPageSelector.GET_STARTED].name,
    icon: 'directions_car',
    permissionsRequired: [ZEVA_PAGE_VIEW_PERMISSION[ZevaPageSelector.ADMIN_SUBSCRIPTION]],
  },
  {
    name: ZEVA_SUBSYSTEMS.admin.name,
    link: '/' + ZEVA_SUBSYSTEMS.admin.slug,
    icon: 'tune',
    subPages: [
      {
        name: ZEVA_PAGES[ZevaPageSelector.ADMIN_PROFILE].name,
        link: `/${ZEVA_SUBSYSTEMS.admin.slug}/${ZEVA_PAGES[ZevaPageSelector.ADMIN_PROFILE].slug}`,
        icon: 'manage_accounts',
        permissionsRequired: [ZEVA_PAGE_VIEW_PERMISSION[ZevaPageSelector.ADMIN_PROFILE]],
      },

      {
        name: ZEVA_PAGES[ZevaPageSelector.ADMIN_SUBSCRIPTION].name,
        link: `/${ZEVA_SUBSYSTEMS.admin.slug}/${
          ZEVA_PAGES[ZevaPageSelector.ADMIN_SUBSCRIPTION].slug
        }`,
        icon: 'star',
        permissionsRequired: [ZEVA_PAGE_VIEW_PERMISSION[ZevaPageSelector.ADMIN_SUBSCRIPTION]],
      },
    ],
  },
];

/* Array that determines the external links menu */
export const EXTERNAL_LINKS: NavigationElement[] = [
  {
    name: 'Help',
    icon: 'contact_support',
    link: `/${ZEVA_PAGES[ZevaPageSelector.FAQ].slug}`,
  },
  {
    name: 'Feedback',
    icon: 'feedback',
    action: (dialog: MatDialog) => {
      dialog.open(FeedbackModalComponent, {
        id: MODAL_ID,
        width: MODAL_WIDTH,
        height: MODAL_HEIGHT,
        maxWidth: '600px',
        maxHeight: '618px',
      });
    },
  },
  {
    name: 'zevaglobal.com',
    link: 'https://www.zevaglobal.com/',
    externalLink: true,
    icon: 'language',
  },
  {
    name: 'iOS App',
    link: 'https://apps.apple.com/us/app/zeva/id6476576625',
    externalLink: true,
    icon: 'apple',
  },
  {
    name: 'Android App',
    link: 'https://play.google.com/store/apps/details?id=com.zevaglobal.zeva',
    externalLink: true,
    icon: 'android',
  },
];
