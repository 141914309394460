import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CachedAlerts, CachedSnapshots } from 'src/app/shared/vehicle/services/real-time.service';
import { Vehicle } from '../../../shared/vehicle/models/vehicle.class';
import { VehicleFilters } from './components/live-query-list/live-query-list.static';
import { VehicleDictionary } from './live.models';
import { VehicleAlerts, VehicleStates } from './live.static';

@Injectable()
export class LiveDataService {
  /* Main vehicle data dictionary */
  private vehicles: VehicleDictionary = {};
  private vehiclesSubject = new BehaviorSubject<VehicleDictionary>(this.vehicles);
  public vehicles$ = this.vehiclesSubject.asObservable();

  /* Selected vehicle filter */
  private vehicleFilter: VehicleFilters = VehicleFilters.ALL;
  public vehicleFilterSubject = new BehaviorSubject<VehicleFilters>(this.vehicleFilter);
  public vehicleFilter$ = this.vehicleFilterSubject.asObservable();

  constructor() {}

  /**
   * Overwrites the current vehicle list with the new one.
   * @param {Vehicle[]} rawVehicleList - The new vehicle list.
   * @author Juan Corral
   */
  public overwriteVehicleList(rawVehicleList: Vehicle[]): void {
    this.vehicles = {};

    for (const vehicle of rawVehicleList) {
      const id = vehicle.id;

      this.vehicles[id] = {
        ...vehicle,
        states: new Set<VehicleStates>().add(VehicleStates.ASLEEP),
        alerts: new Set<VehicleAlerts>(),
        filteredOut: false,
      };
    }
    this.vehiclesSubject.next(this.vehicles);
  }

  /**
   * Updates vehicle states based on the provided snapshots.
   * @param {CachedSnapshots} snapshots - The snapshots to update the states with.
   * @author Anton Valeev
   */
  public updateVehicleStates(snapshots: CachedSnapshots): void {
    const vehicles = Object.values(this.vehicles);
    if (vehicles.length === 0) return;

    // Loop through all vehicle and update the vehicle's state
    for (const vehicle of vehicles) {
      // Get the snapshot for the vehicle
      const snapshot = snapshots[vehicle.vin];
      if (!snapshot) continue;

      // If vehicle is not online, mark the vehicle as ASLEEP
      if (!snapshot.online) {
        vehicle.states = new Set<VehicleStates>().add(VehicleStates.ASLEEP);
        vehicle.lastSnapshot = snapshot;
        continue;
      }

      vehicle.states = new Set<VehicleStates>().add(VehicleStates.ONLINE);

      // If vehicle's shift state is in Drive or Reverse, mark the vehicle as MOVING
      if (snapshot.shift_state === 'D' || snapshot.shift_state === 'R') {
        vehicle.states.add(VehicleStates.MOVING);

        // If the vehicle hasn't changed position since last update, mark as STOPPED
        // if (
        //   vehicle.lastSnapshot?.latitude === snapshot.latitude &&
        //   vehicle.lastSnapshot?.longitude === snapshot.longitude
        // )
        //   vehicle.states.add(VehicleStates.STOPPED);
      } else {
        vehicle.states.add(VehicleStates.PARKED);

        // If the vehicle is charging, mark the vehicle as CHARGING
        if (snapshot.charging) vehicle.states.add(VehicleStates.CHARGING);
      }

      // Update vehicle's cached data
      vehicle.lastSnapshot = snapshot;
    }

    this.vehiclesSubject.next(this.vehicles);
  }

  /**
   * Updates the vehicle's filtered status based on the provided filter
   * @param {string} vehicleId - The id of the vehicle to update
   * @param {VehicleFilter} filter - The selected filter
   * @param {string[]} customList - Array of vehicle id's to include
   *   (only taken into account on 'custom' filter)
   * @author Juan Corral
   */
  public updateVehicleFilteredStatus(
    vehicleId: string,
    filter: VehicleFilters,
    customList: string[]
  ): void {
    const vehicle = this.vehicles[vehicleId];

    // Determine if marker has to be shown or hidden
    let show = false;
    switch (filter) {
      case VehicleFilters.ALL:
        show = true;
        break;
      case VehicleFilters.CUSTOM:
        if (customList.includes(vehicleId)) show = true;
        break;
      default: // Casting for TS to understand
        if (vehicle.states.has(<VehicleStates>(<unknown>filter))) show = true;
        break;
    }

    if (show) vehicle.filteredOut = false;
    else vehicle.filteredOut = true;

    // Update in all components
    this.vehiclesSubject.next(this.vehicles);
  }

  /**
   * Updates the vehicles' alerts based on the provided data.
   * @param {CachedAlerts} alerts - The data containing the alerts to update.
   * @author Juan Corral
   */
  public updateVehicleAlerts(alerts: CachedAlerts): void {
    const vehicles = Object.values(this.vehicles);
    if (vehicles.length === 0) return;

    // Loop through all vehicle and update the vehicle's alerts
    for (const vehicle of vehicles) {
      const alert = alerts[vehicle.vin];
      vehicle.alerts = new Set<VehicleAlerts>();

      if (alert) {
        if (alert.geofence) vehicle.alerts.add(VehicleAlerts.GEOFENCE);
        if (alert.high_speed_alert) vehicle.alerts.add(VehicleAlerts.SPEED);
        if (alert.high_speed_alert) vehicle.alerts.add(VehicleAlerts.BATTERY);
        if (alert.overcharge_alert) vehicle.alerts.add(VehicleAlerts.OVERCHARGE);
      }
      if (vehicle.alerts.size > 0) vehicle.states.add(VehicleStates.ALERT);
      else vehicle.states.delete(VehicleStates.ALERT);
    }

    this.vehiclesSubject.next(this.vehicles);
  }

  /**
   * Changes the selected vehicle filter.
   * @param {VehicleFilter} filter - The new selected filter.
   * @author Juan Corral
   */
  public changeVehicleFilter(filter: VehicleFilters): void {
    this.vehicleFilter = filter;
    this.vehicleFilterSubject.next(this.vehicleFilter);
  }

  public latest(): VehicleDictionary {
    return this.vehicles;
  }
}
