/** Generic model for the identification of a model */
export type ObjectIdentification = {
  id: number;
  name: string;
  slug: string;
};

/** Generic model for the identification of a model */
export type ZevaSubsystemsInterface = {
  insights: ObjectIdentification;
  analytics: ObjectIdentification;
  monitoring: ObjectIdentification;
  maintenance: ObjectIdentification;
  admin: ObjectIdentification;
};

/** Mapping of all zeva pages and sub-pages selectors */
export enum ZevaPageSelector {
  // Insights
  INSIGHTS = 'insights-main',
  // Analytics
  // ANALYTICS_FLEET = 'analytics-fleet',
  ANALYTICS_VEHICLE = 'analytics-vehicle',
  // Monitoring
  MONITORING_LIVE = 'monitoring-live',
  MONITORING_REPLAY = 'monitoring-replay',
  MONITORING_CONTROL = 'monitoring-control',
  MONITORING_EN = 'monitoring-energy',
  MONITORING_GEOFENCES = 'monitoring-geofences',
  // Maintenance
  MAINTENANCE_LIST = 'maintenance-list',
  MAINTENANCE_CREATE = 'maintenance-create',
  MAINTENANCE_EDIT = 'maintenance-edit',
  MAINTENANCE_OVERVIEW = 'maintenance-overview',
  // Admin
  ADMIN_PROFILE = 'admin-profile',
  ADMIN_FLEET = 'admin-fleet',
  ADMIN_ACCOUNT = 'admin-account',
  ADMIN_SUBSCRIPTION = 'admin-subscription',
  ADMIN_MANAGE_SUBSCRIPTION = 'admin-manage-subscription',
  ADMIN_MANAGE_VEHICLES = 'admin-manage-vehicles',
  ADMIN_PAYMENT_DETAILS = 'admin-payment-details',
  ADMIN_PAYMENT_HISTORY = 'admin-payment-history',
  ADMIN_UPDATE_SUBSCRIPTION = 'admin-update-subscription',
  // FAQ
  FAQ = 'faq',
  GET_STARTED = 'get-started',
}

/** Type for a mapping of zeva pages and sub-pages into a static dictionary */
export type ZevaPageDictionary = { [key in ZevaPageSelector]: ObjectIdentification };

/**
 * Timed data Tuple type representing a date entry for a particular date-time \
 * `0` - datetime string \
 * `1` - data entry for this datetime, can be either a numeric value or a string
 */
export type TimeDataTuple = [string, number | string];
// undefined to cater to missing values for certain dates
export type DisplayTimeDataTuple = [string, number | string | undefined];

/**
 * Template type of Data Transfer Objects
 * that are supposed to return comparable datasets for Current and Comparison periods
 */
export type CurrentCompareDTO<T = unknown> = {
  current: T;
  compare: T;
};

export const enum WeekStartLocale {
  MONDAY = 'Monday',
  SUNDAY = 'Sunday',
}
