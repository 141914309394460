import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { TaMapsModule } from '@temerity-analytics/ngx-ta-maps';
import { SuperchargersModule } from 'src/app/shared/superchargers/superchargers.module';
import { CardGridModule } from '../../../../core/card-grid/card-grid.module';
import { SpeedLegendComponent } from './speed-legend/speed-legend.component';
import { UsageAnalyticsHeatmapComponent } from './usage-analytics-heatmap.component';

@NgModule({
  declarations: [UsageAnalyticsHeatmapComponent, SpeedLegendComponent],
  imports: [
    CommonModule,
    CardGridModule,
    GoogleMapsModule,
    TaMapsModule,
    MatCheckboxModule,
    MatIconModule,
    SuperchargersModule,
    TranslocoModule,
  ],
  exports: [UsageAnalyticsHeatmapComponent, SpeedLegendComponent],
})
export class UsageAnalyticsHeatmapModule { }
