<div class="idle-warning-container">
  <button mat-icon-button (click)="onStay()">
    <mat-icon> close </mat-icon>
  </button>

  <div class="contents">
    <p class="mat-headline-3 title">
      <strong>
        {{ 'sessionTimeout.title' | transloco }}
      </strong>
    </p>

    <p *ngIf="isIdle; then warn; else inquire"></p>
    <ng-template #warn>{{
      'sessionTimeout.warn' | transloco : { countdown: countdown }
    }}</ng-template>
    <ng-template #inquire>{{ 'sessionTimeout.inquire' | transloco }}</ng-template>

    <div mat-dialog-actions class="inactivity-dialog-options">
      <button mat-stroked-button color="primary" (click)="onLeave()">
        {{ 'sessionTimeout.signOut' | transloco }}
      </button>
      <button mat-flat-button color="primary" (click)="onStay()" cdkFocusInitial>
        {{ 'sessionTimeout.stay' | transloco }}
      </button>
    </div>
  </div>
</div>
