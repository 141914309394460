<div class="footer th-color-secondary-text">
  <span><!-- offset element for flexbox --></span>
  <span class="copyright">
    {{ 'footer.copyright' | transloco }} &copy; {{ year }}
    <a href="https://zevaglobal.com/" target="_blank">{{ 'footer.company' | transloco }}</a>.
    {{ 'footer.rightsReserved' | transloco }} |
    <a routerLink="/terms-of-use" target="_blank">{{ 'Terms of Use' | transloco }}</a> |
    <a routerLink="/privacy-policy" target="_blank">{{ 'Privacy Policy' | transloco }}</a> |
    <a routerLink="/terms-of-service" target="_blank">{{ 'Terms of Service' | transloco }}</a>
  </span>
  <span class="version">{{ debug ? appVersion : '' }}</span>
</div>
